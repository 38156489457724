import React from "react"
import { Layout, Header } from "../components"
import { GatsbySeo, LocalBusinessJsonLd } from "gatsby-plugin-next-seo"

const Home = () => {
  return (
    <Layout>
      <GatsbySeo
        title={process.env.SITE_NAME}
        description="To Be On The Web voor wordpress websites, web applicaties en hosting."
      />
      <LocalBusinessJsonLd
        type="LocalBusiness"
        id={process.env.SITE_URL}
        name={process.env.SITE_NAME}
        description="To Be On The Web voor wordpress websites, web applicaties en hosting."
        url={process.env.SITE_URL}
        telephone="06-29478385"
        address={{
          streetAddress: "Kievit 12 b-14",
          addressLocality: "Baarle-Nassau",
          postalCode: "5111HD",
          addressCountry: "NL",
        }}
        geo={{
          latitude: "51.42526",
          longitude: "4.9606503",
        }}
        images={["https://www.tobeontheweb.nl/programming.jpg"]}
      />
      <Header />

      <div className="max-w-screen-xl mx-auto p-4 lg:px-8">
        <div className="-mx-4 flex max-w-5xl flex-wrap">
          <div className="w-full px-4 md:w-2/3">
            <div className="mt-6">
              Om uw website te implementeren gebruiken wij de volgende
              technologieën / oplossingen
              <ul className="mt-2 list-disc">
                <li className="ml-4">Gatsby (zeer snelle statische websites)</li>
                <li className="ml-4">Laravel Framework - PHP</li>
                <li className="ml-4">Wordpress</li>
              </ul>
            </div>
            <div className="mt-6">
              Uw websites zijn eenvoudig zelf aan te passen met behulp van een
              Content Management Systeem. Onze klanten zijn met name
              particulieren en het midden- en kleinbedrijf (MKB).
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Home
